:root {
    /* Main top nav bar height*/
    --navbar-height: 56px;
    /* Size of spotify player bar */
    --player-height: 90px;
    /* Height of the small bar to control which content is main focused (Lyrics/Settings/Etc)*/
    --focused-content-nav-height: 35px;

    --spotify-black: rgb();
    --spotify-active-text: rgb(30, 215, 96);

    --genify-nav-bg: rgb(17, 17, 17);
    --genify-light-background: rgb(40,40,40);
    --genify-light-background-hover: rgb(35, 35, 35);
    /* Currently Spotify black color */
    --genify-dark-background: rgb(25, 20, 20);

    --default-text-color: rgb(255, 255, 255);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Make everything match 100%, set font white */
html, body, #root {
    height: 100%!important;
    color: var(--default-text-color);
}

.genify-home {
    height: calc(100% - var(--navbar-height));
}

.genify-navbar {
    height: var(--navbar-height)!important;
}

.genify-player {
    height: var(--player-height);
    background-color: var(--genify-light-background);
}

/* Div that contains lyrics */
.lyrics-content {
    white-space: pre-wrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.scrollable-main-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

/* Total container that encompases content nav and content */
.main-content-container {
    height: calc(100% - var(--player-height))!important;
    margin-right: 0px;
    margin-left: 0px;
}

/* Nav that selects which content is main focus */
.content-nav {
    height: var(--focused-content-nav-height)!important;
}

/* Current selected focused content */
.focused-content-container {
    height: calc(100% - var(--focused-content-nav-height))!important;
}

.song-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.song-info a {
    color: rgb(200, 200, 200);
}
.song-info a:hover {
    color: white;
}

.genify-dark-background {
    background-color: var(--genify-dark-background)!important;
}

.genify-light-background {
    background-color: var(--genify-light-background)!important;
}

.genify-nav-bg {
    background-color: var(--genify-nav-bg)!important;
}

.no-child-margins p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.playback-device-item {
    background: transparent;
    color: var(--default-text-color);
}

.active-device {
    color: var(--spotify-active-text)!important;
}

.playback-devices-title {
    background: transparent;
    border-color: var(--genify-light-background);
}

.content-selector-item {
    cursor: pointer;
}

.active-selection {
    color: var(--spotify-active-text);
}

.horizontal-separator {
    background-color: rgb(32, 32, 32);
    height: 0.15rem;
    width: 100%;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
}

.changelog-modal {
    height: 95%;
    overflow-y: hidden!important;
}

.changelog-modal .modal-dialog {
    height: 95%;
}
.changelog-modal .modal-content {
    height: 95%;
}

.changelog-body {
    background-color: rgb(17, 17, 17)!important;
    border-color: #222;
    font-size: 0.85rem;
    max-height: 95%;
    overflow-y: auto;
}

/* Override default style for bootstrap active switch */
.custom-control-input:checked~.custom-control-label::before {
    border-color: var(--spotify-active-text);
    background-color: var(--spotify-active-text);
}
/* Override default style for bootstrap deactive switch */
.custom-switch .custom-control-label::before {
    border-color: var(--genify-light-background);
    background-color: var(--genify-light-background);
}

/* Bootstrap: Styling for normal Genify modal */
.genify-modal .modal-dialog .modal-content {
    background-color: transparent;
}
.genify-modal .modal-dialog {
    background-color: var(--genify-light-background)!important;
}
 
/* Bootstrap: Styling for normal Genify List Group*/
.genify-list-group {
    background-color: var(--genify-light-background)!important;
}
.genify-list-group .list-group-item {
    background-color: transparent;
    color: var(--default-text-color)!important;
}
.genify-list-group .list-group-item:hover {
    background-color: var(--genify-light-background-hover);
}

/* Bootstrap: Custom styling for Select form control */
.genify-select {
    background-color: var(--genify-dark-background)!important;
    border-color: var(--genify-light-background)!important;
    color: var(--default-text-color)!important;
}
.genify-select option {
    background-color: var(--genify-dark-background)!important;
    border-color: var(--genify-light-background)!important;
    color: var(--default-text-color)!important;
}

.genify-toast {
    background-color: var(--genify-light-background);
}
.genify-toast .toast-header {
    background-color: var(--genify-light-background-hover);
    color: var(--default-text-color);
}